@import "fonts.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.bg {
  background-color: var(--neutral-900);
}

.text {
  color: var(--text-color);
}
html, body {
  height: 100%;
  overflow-x: hidden;
}

#root {
  height: 100%;
  /*overflow: hidden;*/
}

.outer-container {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.z-select {
    width: 100%;
    background-color: rgb(0, 0, 38);
    border-radius: 6px;
    border: 1px solid #4a4a6b;
    padding: 14px 16px;
}

.z-select.sm {
    padding: 4px 24px 4px 4px;
}

.z-input {
    width: 100%;
    background-color: rgb(0, 0, 38);
    border-radius: 6px;
    border: 1px solid #4a4a6b;
    padding: 14px 16px;
}

.z-checkbox {
    width: 24px;
    height: 24px;
    background-color: #94ABFF;
    border-radius: 4px;
    padding: 2px;
}

.picture-xs {
    height: 24px;
    width: 24px;
    border-radius: 50%;
}

.picture-sm {
    height: 32px;
    width: 32px;
    border-radius: 50%;
}

.picture {
    height: 48px;
    width: 48px;
    border-radius: 50%;
}

.picture-lg {
    height: 80px;
    width: 80px;
    border-radius: 50%;
}

.icon {
    height: 24px;
}

.icon-lg {
    height: 32px;
}

.icon-xl {
    height: 48px;
}

@layer base {
  /* Scrollbar styling */
  ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
      @apply bg-neutral-600;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
      @apply bg-neutral-800 rounded-lg;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
      @apply bg-neutral-800;
  }

  /* Handle on active (Grab state) */
  ::-webkit-scrollbar-thumb:active {
      @apply bg-neutral-800;
  }
}

/* TURN OFF OVERSCROLL AS A DEFAULT BEHAVIOUR  */
/* html {
  overscroll-behavior: none;
} */


